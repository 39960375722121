<template>
  <section class="about-company">
    <div class="container">
      <div class="about-company__inner">
        <div class="about-company__image">
          <img
            src="@/assets/images/construction.jpg"
            style="width: 100%; height: 100%; object-fit: cover"
            class="about-img"
            alt="heavycar-image"
          >
        </div>
        <div class="about-company__info">
          <div class="about-company__title main__title">
            О компании
          </div>
          <div class="about-company__text main__text">
            Мы предлагаем оборудование для техосмотра автомобилей в виде
            комплексных диагностических<br>
            линий, полностью соответствующих действующим<br>
            нормам и законодательным регламентам.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutCompany'
};
</script>
